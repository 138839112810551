<template>
  <div class="loader-container">
    <overlay-loader :loading="isLoading"/>
    <hcc-table
      @pageChanged="changePage($event, false)"
      @download="download"
      :pagination="rows.length > 5"
      :actualPage="currentPage"
      :title="$t('reports.title')"
      :rowsPerPage="5"
      :columns="columns"
      :rows="reportRows"
      @add="openAddModal"
    >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'statu-info'">
          <status-badge
            :type="props.row.status"
            v-tooltip="props.row.status"
          />
      </span>
    </template>
    </hcc-table>
    <hcc-table
      @pageChanged="changePage($event, true)"
      @delete="deleteSchedule"
      :pagination="scheduleRows.length > 5"
      :actualPage="currentPageSch"
      :title="$t('reports.title-schedule')"
      :rowsPerPage="5"
      :columns="scheduleColumns"
      :rows="scheduleRows"
    />
    <generate-report
      :params="query"
      @close="clearParams"
      @generate="generateReport"
      @schedule="scheduleReport"/>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import executeQuery from '@/utils/gql-api';
import listReports from '@/graphql/queries/listReports.gql';
import createReport from '@/graphql/mutations/createReport.gql';
import scheduleReport from '@/graphql/mutations/scheduleReport.gql';
import removeScheduleReport from '@/graphql/mutations/deleteScheduleReport.gql';
import reportStatusGql from '@/graphql/suscriptions/reportStatus.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import { getToken } from '@/vue-apollo';

export default {
  components: {
    OverlayLoader,
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    GenerateReport: () => import('@/components/GenerateReport.vue'),
    StatusBadge: () => import('@/components/StatusBadge.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      rows: [],
      scheduleRows: [],
      token: getToken(),
      currentPage: 1,
      isLoading: false,
      currentPageSch: 1,
      query: null,
    };
  },
  mounted() {
    this.isLoading = true;
    this.fetchUserReports()
      .then(() => this.checkRoute(this.$route.params));
  },
  created() {
    this.fetchTypes();
  },
  watch: {
    reports(newData) {
      this.rows = newData;
    },
    schedule(newData) {
      this.scheduleRows = newData.map(rep => this.mapSchedule(rep));
    },
    $route(newVal) {
      setTimeout(() => this.checkRoute(newVal.params), 1000);
    },
  },
  apollo: {
    $subscribe: {
      reportStatus: {
        query: reportStatusGql,
        result({ data }) {
          const { reportStatus } = data;
          this.addReport(reportStatus);
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      },
    },
  },
  computed: {
    ...mapState('reports', ['reports', 'schedule', 'types']),
    ...mapState('shared', ['lang']),
    columns() {
      return [{
        label: this.$t('common.name'),
        field: 'report',
      }, {
        label: this.$t('filter.campaign'),
        field: 'campaign.name',
      }, {
        label: this.$t('reports.date'),
        field: 'createdAt',
      }, {
        label: this.$t('reports.range'),
        field: 'range',
      }, {
        label: this.$t('common.status'),
        field: 'statu-info',
      }, {
        label: this.$t('reports.expire'),
        field: 'expiredAt',
      }];
    },
    scheduleColumns() {
      return [{
        label: this.$t('common.name'),
        field: 'name',
      }, {
        label: this.$t('filter.campaign'),
        field: 'campaign.name',
      }, {
        label: this.$t('reports.schedule.frecuency'),
        field: 'frequency',
      }, {
        label: this.$t('reports.schedule.next'),
        field: 'nextReport',
      }];
    },
    reportRows() {
      return this.rows.map(rep => this.mapReport(rep));
    },
  },
  methods: {
    ...mapMutations('reports', [
      'setReports',
      'addReport',
      'setSchedule',
      'addSchedule',
      'removeSchedule',
      'setTypes',
    ]),
    ...mapActions('reports', ['fetchTypes']),
    async fetchUserReports() {
      const { reports, schedules } = await executeQuery('listReports', listReports);
      this.setReports(reports);
      this.setSchedule(schedules);
      this.isLoading = false;
    },
    checkRoute(params) {
      if (params.companyId) {
        this.query = params;
        this.openAddModal();
      }
    },
    openAddModal() {
      this.$nextTick(() => {
        this.$modal.show('report');
      });
    },
    async generateReport(props) {
      const { data } = await this.$apollo.mutate({
        mutation: createReport,
        variables: props,
      });

      this.addReport(data.createReport);
      this.$toasted.global.success(this.$t('reports.information'));
    },
    mapReport(report) {
      let { createdAt, expiredAt, params: { start, end } } = report;
      const campaign = report.params.campaigns && report.params.campaigns.length > 0
        ? { name: report.params.campaigns.map(({ name }) => name).join(', ') }
        : report.campaign;

      end = this.$moment(end).local().format('DD/MM/YYYY');
      start = this.$moment(start).local().format('DD/MM/YYYY');
      createdAt = this.globalFormatRelativeDateTime(createdAt, true);
      expiredAt = expiredAt ? this.$moment(expiredAt).local().format('DD/MM/YYYY h:mm A') : report.status;
      const type = this.reportType(report);
      return {
        ...report,
        campaign,
        createdAt,
        expiredAt,
        range: `${start} - ${end}`,
        report: type,
      };
    },
    mapSchedule(schedule) {
      let { nextReport, frequency } = schedule;
      nextReport = this.$moment(nextReport).local().format('DD/MM/YYYY h:mm A');
      frequency = this.$t(`reports.${frequency}`);
      const campaign = schedule.campaigns && schedule.campaigns.length > 0
        ? { name: schedule.campaigns.map(({ name }) => name).join(', ') }
        : schedule.campaign;

      return {
        ...schedule,
        campaign,
        nextReport,
        frequency,
      };
    },
    download(props) {
      if (props.status === 'completed') {
        window.open(props.file, '_blank');
      } else {
        this.$toasted.global.error(this.$t('reports.error'));
      }
    },
    changePage(newPage, ref) {
      if (ref) {
        this.currentPageSch = newPage;
      } else {
        this.currentPage = newPage;
      }
    },
    reportType({ report, params }) {
      const clientName = params.clientName || 'client';
      const type = this.types.find(({ key }) => key === report);
      if (report === 'ConversationClientReport') {
        return `${clientName}-${this.$t('reports.report-client')}`;
      }
      return this.lang === 'es' ? type.es : type.en;
    },
    async scheduleReport(variables) {
      const { data } = await this.$apollo.mutate({
        mutation: scheduleReport,
        variables,
      });
      this.addSchedule(data.scheduleReport);
    },
    deleteSchedule(props) {
      this.$modal.show('confirmation', {
        title: this.$t('common.delete'),
        description: this.$t('reports.schedule.delete'),
        variant: 'error',
        confirm: () => this.removeReport(props),
      });
    },
    async removeReport({ id }) {
      await this.$apollo.mutate({
        mutation: removeScheduleReport,
        variables: { id },
      });
      this.removeSchedule(id);
    },
    clearParams() {
      this.query = null;
    },
  },
};

</script>

<style scoped lang="scss">
  .loader-container {
    position: relative;
  }
</style>
